
  import { API, requests } from '@/api/apis'
  import { defineComponent, onMounted, reactive, ref, toRefs } from 'vue'
  import { assignIn } from '@/common/utils/lodash'
  import { notification } from 'ant-design-vue'
  import { formatDate } from '@/common/utils/dayjs'
  import { tableProps } from '@/common/utils/common'
  import { useRouter } from 'vue-router'
  import { SortType } from '@/common/index'
  import { getSorter } from '@/common/utils/common'
  import MyPlansStep from '@/components/MyStep/MyPlansStep.vue'
  import MyEffectPicture from '@/common/components/Download/EffectPicture.vue'

  import PageSearch from './components/PageSearch.vue'
  export default defineComponent({
    inject: ['tagColor'],
    components: {
      MyPlansStep,
      PageSearch,
      MyEffectPicture,
    },
    setup() {
      const router = useRouter()
      const search = {
        form: reactive({
          projects_id: undefined,
          status: undefined,
          create_user_id: undefined,
          director_id: undefined,
          current_user_id: undefined,
          code: undefined,
          current_steps_label: undefined,
          created_at: undefined,
          updated_at: undefined,
        }),
        onSubmit: (data: any) => {
          const { page } = toRefs(pagination.model)
          search.form = data
          page.value = 1
          doRequest()
        },
      }
      const modal = {
        name: ref('CreateModal'),
        title: ref(''),
        data: ref({}),
        show: ref(false),
        onCancel: () => {
          //关闭
          modal.show.value = false
        },
        openModal: (name: string, title: string, data: {}) => {
          modal.name.value = name
          modal.title.value = title
          modal.data.value = data
          modal.show.value = true
        },
        onFinish: () => {
          //关闭/添加成功
          modal.show.value = false
          doRequest()
        },
      }
      const pagination = {
        data: ref({}),
        model: reactive({
          page: 1,
          perPage: 20,
        }),
        onChange: (values: any) => {
          const { page, perPage } = toRefs(pagination.model)
          page.value = values.page
          perPage.value = values.perPage
          doRequest()
        },
      }

      const table = {
        title: '已提交（打图）',
        props: tableProps,
        columns: [
          {
            title: 'ID',
            dataIndex: 'id',
            width: 100,
          },
          {
            title: '策划表编号',
            dataIndex: 'code',
            sorter: true,
          },
          {
            title: '项目编号',
            dataIndex: 'project.code',
          },
          {
            title: '项目名称',
            dataIndex: 'project.name',
          },
          {
            title: '提交人',
            dataIndex: 'create_user.realname',
            width: 100,
            sorter: true,
          },
          {
            title: '状态',
            width: 100,
            dataIndex: 'status',
            sorter: true,
            slots: { customRender: 'status' },
          },
          {
            title: '提交时间',
            width: 150,
            sorter: true,
            dataIndex: 'created_at',
            customRender: ({ record }: { record: { created_at: '' } }) =>
              formatDate(record.created_at),
          },
          {
            title: '操作',
            slots: { customRender: 'action' },
            width: 300,
          },
        ],
        data: ref([]),
        model: ref(),
        onChange: (_1: unknown, _2: unknown, sorter: SortType) => {
          table.model.value = getSorter(sorter)
          doRequest()
        },
        onRemove: (id: number) => {
          //删除
          // requests({
          //   url: API.Projects.Destroy,
          //   data: { id: id },
          // }).then((res) => {
          //   notification['success']({
          //     message: '提示',
          //     description: '删除成功！',
          //   })
          //   doRequest()
          // })
        },
      }
      const doRequest = () => {
        const params = assignIn(
          search.form,
          table.model.value,
          pagination.model
        )
        requests({
          url: API.project_graph_accepts.MyList,
          data: params,
        }).then((res) => {
          const { data, meta } = res as any
          table.data.value = data
          pagination.data.value = meta
          console.log(res, '数据')
        })
      }
      const queryProjectPlans = (id: any) => {
        router.push(`/admin/map/appliers_show?id=${id}`)
      }
      onMounted(() => {
        doRequest()
      })
      return {
        search,
        table,
        modal,
        pagination,
        queryProjectPlans,
      }
    },
  })
